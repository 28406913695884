<template>
  <div ref="main" class="position-absolute top-0 start-0 w-100 h-100">
    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="row align-items-center justify-content-center w-100">
          
        <div class="col-lg-12 text-center">
          <router-link to="/" class="mb-4 d-block auth-logo animate__animated animate__fadeInDown">
                      <img src="@/assets/images/logo-home.png" alt height="128" class="logo logo-dark d-inline-block" />
                      <img src="@/assets/images/logo-home.png" alt height="128" class="logo logo-light" />
                    </router-link>
          </div>
          <div class="col-auto" style="width: 420px;">
            <form @submit.prevent="login">
            <div class="card rounded-3 bg-white">
              <div class="card-body p-4 py-5">
                <div class="text-center mt-2">
                  <h5 class="text-primary animate__animated animate__fadeInUp mb-1">Welcome to {{items[0].text}}</h5>
                  <p class="text-muted animate__animated animate__fadeInDown animate__delay-1s">Please login to access {{items[0].text}}.</p>
                </div>
                <div class="col-12">
                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-15">
                      <i class="mdi mdi-account-outline me-1 font-size-16 text-primary"></i> Login {{$t('siteLang.Username')}}
                    </label>
                    <input type="text" class="form-control form-control-lg" :placeholder="$t('siteLang.Username')"  autocomplete="" v-model.trim="formData.username" :class="{
                      'is-invalid': submitted && $v.formData.username.$error
                    }">
                    <div v-if="submitted && $v.formData.username.$error" class="invalid-feedback ">
                      <span v-if="!$v.formData.username.required">{{$t('siteLang.Username')}} {{$t('siteLang.isRequired')}}</span>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label class="form-check-label mb-2 font-size-15 d-flex">
                      <div>
                        <i class="mdi mdi-lock-outline me-1 font-size-16 text-primary"></i> Login {{ $t('siteLang.Password') }}
                      </div>
                      <a @click="forgotPassword" class="d-block ms-auto text-end font-size-13 mt-1">
                      {{$t('siteLang.ForgotPassword')}}
                    </a>
                    </label>
                    <div class="input-group">
                      <input :type="passwordFieldType" class="form-control form-control-lg" autocomplete="" :placeholder="$t('siteLang.Password')" v-model.trim="formData.password" :class="{
                'is-invalid': submitted && $v.formData.password.$error,
              }">
                      <span class="input-group-text rounded-end" @click="togglePasswordFieldType">
                        <i class="mdi" :class="{'mdi-eye-off': !showPassword , ' mdi-eye':showPassword }"></i>
                      </span>
                      <div v-if="submitted && $v.formData.password.$error" class="invalid-feedback">
                        <span v-if="!$v.formData.password.required">{{ $t('siteLang.Password') }} {{$t('siteLang.isRequired')}}</span>
                      </div>
                    </div>
                   
                  </div>
                  <!-- <div class="form-group text-center text-muted my-3 font-size-13">
                    {{ $t('siteLang.Yourpersonalinforsafe') }}
                  </div> -->
                  <div class="d-grid gap-2" >
                    <button class="btn btn-info btn-lg fw-medium" :disabled="loading" type="submit" >
                      <span v-if="loading">{{$t('siteLang.Loging')}}.</span>
                      <span v-else> {{$t('siteLang.Login')}} </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            </form>
            <div class="mt-2 text-center text-muted animate__animated animate__fadeInUp">
              <p> © {{ new Date().getFullYear() }} {{items[0].text}}. All Rights Reserved. Privacy Policy </p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
    </div>
   
    <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import Common from "@/components/common";
  import {
    required,
    helpers
  } from "vuelidate/lib/validators";
  export const countryCodeAndPhone = (value) => {
    const regex = /^(60|65)\d{8,15}$/
    return helpers.regex('countryCodeAndPhone', regex)(value)
  }
  import axios from "axios";
  import Swal from "sweetalert2";
  /**
   * Login component
   */
  export default {
    components: {
      Common
    },
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        selectedLang: {},
        languages: [{
            flag: require("@/assets/images/flags/us.jpg"),
            language: "en",
            title: "English",
          },
          // {
          //   flag: require("@/assets/images/flags/china.png"),
          //   language: "zh",
          //   title: "Chinese",
          // },
          {
            flag: require("@/assets/images/flags/indonesia.png"),
            language: "id",
            title: "Indonesia",
          },
        ],
        loading: false,
        showLottie: false,
        showPassword: false,
        formData: {
          username: "",
          password: "",
          agreeTerm: false,
        },
        submitted: false,
        title: '',
        items: [{
          text: appConfig.title,
          href: "/",
        }, {
          text: '',
          active: true,
        }, ],
      };
    },
    validations: {
      formData: {
        username: {
          required,
        },
        password: {
          required,
        },
      }
    },
    computed: {
      passwordFieldType() {
        return this.showPassword ? 'text' : 'password'
      }
    },
    mounted() {
      // this.$refs.main.changeVerticalTopBar("",false)
      if (this.$refs.commonFunc.getSetLang() == undefined) {
        this.$refs.commonFunc.setLang('en')
      }
      const Language = this.languages.find(lang => lang.language === this.$i18n.locale);
      this.selectedLang = Language
      this.title = this.$t('siteLang.Login')
      this.items[1].text = this.$t('siteLang.Login');

      // document.body.style.backgroundImage = "url('https://static.vecteezy.com/system/resources/previews/023/253/333/non_2x/3d-background-modern-wave-curve-abstract-presentation-adorned-with-abstract-decoration-halftone-gradients-and-3d-illustration-is-an-ideal-choice-for-a-landing-page-website-vector.jpg')";
      // document.body.style.backgroundColor = "#0c4750";
      // document.body.style.height = "100%";
      // document.body.style.backgroundPosition = "center";
      // document.body.style.backgroundRepeat = "no-repeat";
      // document.body.style.backgroundSize = "cover";
    },
    created() {
      this.$Progress.finish();
    },
    methods: {
      togglePasswordFieldType() {
        this.showPassword = !this.showPassword
      },
      login() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("username", this.formData.username);
          bodyFormData.append("password", this.formData.password);
          // var postUrl = ""
          // if (this.formData.username=='admin'){
          //   postUrl = appConfig.StagingAPI + 'login.php'
          // }else{
          //   //postUrl = appConfig.APIHost + 'controller/account/verifyAccount'
          //   postUrl = appConfig.APIHostAdmin + 'verifyAccount'
          // }
          axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'verifyAccount',
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              var merchantInfo = {}
              var resellerInfo = {}
              var availableModules = []
              //if login as merchant then give merchant info
              if (resData.data.account_type=='merchant'){
                merchantInfo= resData.info
              }
              if (resData.data.account_type=='reseller'){
                resellerInfo= resData.info
              }

              resData.info.modules.forEach(element => {
                if (element.view_permission==1){
                  availableModules.push(element)
                }
              });
              const data = {
                "username": resData.data.username,
                "access_token": resData.data.token,
                "name": resData.data.name || '',
                "phone": '',
                "lang": 'en',
                "account_type": resData.data.account_type,
                "merchant_code": resData.data.merchant_code || "",
                "merchant_key": resData.data.merchant_key || "",
                "merchant_info":merchantInfo,
                "reseller_info":resellerInfo,
                "skype_id":resData.skype_id,
                "telegram_id":resData.telegram_id,
                "modules":availableModules
                // "modules":[
                //   {
                //     "name": "payin",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   },
                //   {
                //     "name": "payout",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   },
                //   {
                //     "name": "settlements",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   },
                //   {
                //     "name": "reports",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   },
                //   {
                //     "name": "technical",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   },
                //   {
                //     "name": "sub_accounts",
                //     "view_permission":"1",
                //     "create_modify_permission":"1"
                //   }
                // ]
              }
              // if (resData.data.account_type!=='merchant'){
              //   data.modules.push(
              //     {
              //       "name": "account_management",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     },
              //     {
              //       "name": "reseller_contracts",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     },
              //     {
              //       "name": "withdrawal",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     }
              //     )
                  
              // }
              // if (resData.data.account_type=='admin'){
              //   data.modules.push(
              //     {
              //       "name": "top_up",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     },
              //     {
              //       "name": "administrator",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     }
              //     )
              // }
              // if (resData.data.account_type=='merchant'){
              //   data.modules.push(
              //     {
              //       "name": "my_contracts",
              //       "view_permission":"1",
              //       "create_modify_permission":"1"
              //     }
              //     )
              // }
              
              this.$refs.commonFunc.setData(data)
              // const path = this.$refs.commonFunc.getPreviosLink()
              // console.log(path)
              // this.$router.push(path)
              this.$router.push({
                name: 'dashboard'
              })
              document.body.style.backgroundColor = "#f5f6f8";
              // const token = this.$refs.commonFunc.getToken()
              // console.log(token)
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$Progress.fail();
            }
            this.loading = false;
            this.$Progress.finish();
          }).catch((error) => {
            this.loading = false;
            this.$Progress.finish();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#FA6541',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      },
      register() {
        this.$router.push({
          name: 'register'
        })
      },
      setLanguage(value) {
        this.selectedLang = value
        this.$refs.commonFunc.setLang(value.language)
        this.$refs.commonFunc.getSetLang()
      },
      forgotPassword() {
        // this.showLottie = true
        // this.$refs.lottieFunc.showHide = true
        Swal.fire({
          icon: 'info',
          title: this.$t('siteLang.ForgotPassword'),
          iconColor: '#FF4500',
          html: this.$t('siteLang.ForgotPasswordDesc'),
          confirmButtonColor: '#FA6541',
          confirmButtonText: this.$t('siteLang.Okay'),
        })
      }
    },
    middleware: "authentication",
  };
</script>